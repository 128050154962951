import { StaticQuery, graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { H1, H2, P } from '@components/Typography';
import { Section, Container, Underline } from '@components/_global';
import Link from '@components/ExternalLink';
import Member from '@components/Member';
import TeamElements from '@components/TeamElements';
import { breakpoint } from '@constants';


import { MEMBERS, ADVISORS, IN_MEMORY_OF } from '@constants/members';

const MembersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 1024px;
  gap: 48px 32px;

  @media (max-width: ${breakpoint.max.md}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${breakpoint.max.sm}) {
    grid-template-columns: repeat(2, 1fr);
    max-width: 90%;
  }
`;

const MembersFlex = styled.div`
  display: flex;
  gap: 48px;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 1024px;
`;

const MemberTypesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 72px;
  align-items: center;
`;

const MemberTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  align-items: center;
`;

const VisuallyHidden = styled.h2`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;

const MemberType = ({ title, children, isTitleVisible = true }) => (
  <MemberTypeContainer>
    {isTitleVisible ? <H2>{title}</H2> : <VisuallyHidden>{title}</VisuallyHidden>}
    {children}
  </MemberTypeContainer>
)

// ____________________________________________________________________
// Components

const Members = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "members" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(quality: 100, maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const getImage = (member) => {
        const imageEdge = data.allFile.edges.find(({ node }) => node.relativePath === member.image);
        const image = imageEdge.node;

        return image.childImageSharp.fluid;
      };

      const sortedMembers = MEMBERS.sort((a, b) => a.firstName.localeCompare(b.firstName));

      return (
        <MemberTypesContainer>
          <MemberType title="Devfolio Team" isTitleVisible={false}>
            <MembersGrid>
              {sortedMembers.slice(0, 12).map((member, index) => (
                <Member {...member} fluid={getImage(member)} key={index} />
              ))}
            </MembersGrid>
            <MembersFlex>
              {sortedMembers.slice(12).map((member, index) => (
                <Member {...member} fluid={getImage(member)} key={index} />
              ))}
            </MembersFlex>
          </MemberType>
          <MemberType title="Advisors">
            <MembersFlex>
              {ADVISORS.map((member, index) => (
                <Member {...member} fluid={getImage(member)} key={index} />
              ))}
            </MembersFlex>
          </MemberType>
          <MemberType title="In memory of">
            <MembersFlex>
              <Member {...IN_MEMORY_OF} fluid={getImage(IN_MEMORY_OF)} hasStar />
            </MembersFlex>
          </MemberType>
        </MemberTypesContainer>
      )
    }}
  />
);

const TeamsDetails = styled.div`
  display: flex;
  position: relative;
  max-width: 1064px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin: 0 auto;
`

const TeamMission = styled(P)`
  padding: 40px 0px;
  margin: auto;
  text-align: center;
  @media (max-width: ${breakpoint.max.md}) {
    padding-top: 16px;
  }
  @media (max-width: ${breakpoint.max.sm}) {
    display: none;
  }
`;

const Team = () => {
  return (
    <Section id="team" grid>
      <Container>
        <div id="hero-team" css="margin: 0 auto; text-align: center; position: relative">
          <div css="margin: 24px 0">
            <H1>
              We do <Underline>it all!</Underline>
            </H1>
          </div>

          <P size="large" color="lightGrey">
            Well, most of it.
          </P>

          <TeamElements.Hero />
        </div>

        <TeamElements.MembersHeader />
        <TeamsDetails>
          <TeamMission size="medium" color="lightGrey">
            You have the potential to build something that can change the world.
            We make sure that you get at least one shot at doing so. At our core, we want to create
            opportunities for builders through initiatives like <Link href="https://ethindia.co">ETHIndia</Link>: the world's largest Ethereum
            hackathon, <Link href="https://wmn.community">wmn;</Link>: a hackathon 
            for women and non-binary people, and <Link href="https://hackinout.co">InOut</Link>:
            Devfolio’s alma mater. To date, we’ve helped organize <Link href="https://devfolio.co/hackathons">1,000+ hackathons worldwide</Link>, 
            bringing <Link href="https://devfolio.co/search?primary_filter=projects">45,000+ ideas to life</Link>.
          </TeamMission>
          <Members />
          <TeamElements.Members />
        </TeamsDetails>
        <TeamElements.MembersFooter />
      </Container>
    </Section>
  );
};
export default React.memo(Team);
